/* eslint-disable camelcase,jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'gatsby';
import { useNavigate } from '@reach/router';
import AuthorSvg from '../../../images/blog/author-sign.svg';
import '../styles.scss';

const BlogFeaturedPostCard = (props) => {
  const navigate = useNavigate();
  const { title, slug, reading_time, published_at, primary_tag, excerpt, primary_author, feature_image } = props;

  const handleAuthorClick = (event) => {
    event.preventDefault();
    navigate(`/blog/author/${primary_author.slug}/`);
  };

  return (
    <Link to={`/blog/${slug}`} className="featured-article">
      <article className="article-card-container">
        <div className="image-container">
          <img src={feature_image} alt={title} />
        </div>
        <div className="article-card-body">
          <h6 className="article-tag"><Link to={`/blog/category/${primary_tag?.slug}`}>{primary_tag?.name}</Link></h6>
          <h5 className="article-title">{title}</h5>
          <p className="article-excerpt">{excerpt}</p>
          <div className="article-meta">
            <div className="meta">
              <p>{published_at}</p>
              <p>•</p>
              <p>
                {reading_time}
                {' '}
                mins read
              </p>
            </div>
            <div className="author">
              <div className="author-image" onClick={handleAuthorClick}>
                <img src={primary_author.profile_image || AuthorSvg} alt={primary_author.name} />
              </div>
              <p className="author-name">
                <Link to={`/blog/author/${primary_author?.slug}`}>{primary_author.name}</Link>
              </p>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default BlogFeaturedPostCard;
