/* eslint-disable camelcase */
import React from 'react';
import './styles.scss';
import { Link } from 'gatsby';
import ArrowIcon from '../../images/blog/blog-arrow.svg';
import BlogPostCard from '../blogCards/postCard';

const BlogCategory = (props) => {
  const { edges } = props;
  return (
    <div className="blog-category">
      <div className="category-head">
        <h4>{edges[0].node?.primary_tag?.name}</h4>
        <Link to={`/blog/category/${edges[0].node?.primary_tag?.slug}`}>
          View All
          <img alt="arrow" src={ArrowIcon} />
        </Link>
      </div>
      <div className="posts-container row-new">
        {edges.map(({ node }, index) => {
          if (index > 1) return;
          // eslint-disable-next-line consistent-return
          return (
            <div className="col-6">
              <BlogPostCard id={node.id} {...node} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogCategory;
