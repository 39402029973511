import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import TagsSvg from '../../../images/blog/list.svg';

const MoreTopicsSlider = () => {
  const data = useStaticQuery(graphql`
    {
      allGhostTag(filter: {visibility: {eq: "public"}}) {
        edges {
          node {
            id
            slug
            feature_image
            name
          }
        }
      }
    }
  `);
  const { edges } = data.allGhostTag;

  return (
    <>
      <div className="more-topics-container row-new">
        {edges
          && edges.map(({ node }) => (
            <Link
              to={`/blog/category/${node.slug}`}
              className="tag-card-container col-2"
              key={node.id}
            >
              <img src={node.feature_image || TagsSvg} alt={node.name} />
              <p>{node.name}</p>
            </Link>
          ))}
      </div>
    </>
  );
};

export default MoreTopicsSlider;
