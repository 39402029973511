import React from 'react';
import BlogHeader from '../blogHeader';
import BlogFooter from '../blogFooter';

// eslint-disable-next-line no-unused-vars
const BlogLayout = ({ children }) => (
  <>
    <BlogHeader />
    <main className="blog-main-container">{children}</main>
    <BlogFooter />
  </>
);

export default BlogLayout;
