/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'gatsby';
import '../styles.scss';

const BlogPostCard = (props) => {
  const { title, slug, reading_time, published_at, primary_tag, feature_image, excerpt, primary_author, isLatest } = props;
  return (
    <>
      <Link to={`/blog/${slug}`} className={`blog-card-container ${isLatest ? 'isLatest' : ''}`}>
        <article className="blog-card">
          <div className="blog-image">
            <img src={feature_image} alt={title} />
          </div>
          <div className="card-body">
            <p className="post-category"><Link to={`/blog/category/${primary_tag?.slug}`}>{primary_tag?.name}</Link></p>
            <span className="post-title" title={title}>{title}</span>
            <div className="post-meta">
              <p className="author"><Link to={`/blog/author/${primary_author?.slug}`}>{primary_author.name}</Link></p>
              <p className="date">{published_at}</p>
              <p className="duration">
                {reading_time}
                {' '}
                mins read
              </p>
            </div>
            <p className="excerpt">
              {excerpt}
            </p>
          </div>
        </article>
      </Link>
    </>
  );
};

export default BlogPostCard;
