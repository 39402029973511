/* eslint-disable no-nested-ternary,react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import '../common-styles/blog.scss';
import BlogCategory from '../components/blogCategory';
import BlogFeaturedPostCard from '../components/blogCards/featuredPostCard';
import SEO from '../components/seo';
import BlogPostCard from '../components/blogCards/postCard';
import MoreTopicsSlider from '../components/blogCards/moreTopicsSlider';
import BlogLayout from '../components/layout/blogLayout';
import { saveURLParamToSession } from '../utils';

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 5000,
  autoplay: true
};

const Blog = (props) => {
  const { data } = props;
  const { featured, group, latest } = data || {};
  // eslint-disable-next-line no-console
  // console.log(featured.edges, group, latest.edges);

  useEffect(() => {
    saveURLParamToSession();
  }, []);

  return (
    <BlogLayout>
      <SEO title="Blog" />
      <div className="container">
        {featured?.edges?.length > 0 && (
          <div className="card-container">
            <Slider {...settings}>
              {featured
                && featured.edges
                && featured?.edges?.map(({ node }) => (
                  <BlogFeaturedPostCard {...node} key={node.id} />
                ))}
            </Slider>
            <hr className="d-block d-md-none" />
          </div>
        )}
        <div className="latest-post blog-category">
          <div className="category-head">
            <h4>Latest Posts</h4>
          </div>
          <div className="row-new">
            {latest
              && latest.edges
              && latest.edges.map(({ node }) => (
                <div className="col-4" key={node.id}>
                  <BlogPostCard {...node} isLatest={true} />
                </div>
              ))}
          </div>
        </div>
        <div>
          {group
            && group.group
            && group.group.map(({ edges }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <BlogCategory key={`index_${index}`} edges={edges} />
            ))}
        </div>
        <div className="blog-category d-none">
          <div className="category-head">
            <h4>More Topics</h4>
          </div>
          <div>
            <MoreTopicsSlider />
          </div>
        </div>
      </div>
    </BlogLayout>
  );
};
export default Blog;

export const pageQuery = graphql`
  {
    featured: allGhostPost(
      filter: { featured: { eq: true } }
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          id
          slug
          title
          primary_tag {
            name
            slug
          }
          primary_author {
            name
            profile_image
            slug
          }
          excerpt
          feature_image
          published_at(formatString: "MMM DD, YYYY")
          reading_time
        }
      }
    }
    latest: allGhostPost(
      filter: { featured: { eq: false } }
      sort: { order: DESC, fields: published_at }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          title
          primary_tag {
            name
            slug
          }
          primary_author {
            name
            profile_image
            slug
          }
          excerpt
          feature_image
          published_at(formatString: "MMM DD, YYYY")
          reading_time
        }
      }
    }
    group: allGhostPost(sort: { order: DESC, fields: published_at }) {
      group(field: primary_tag___id) {
        edges {
          node {
            id
            slug
            title
            feature_image
            primary_tag {
              id
              slug
              name
            }
            excerpt
            primary_author {
              name
              profile_image
              slug
            }
            reading_time
            published_at(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
