/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'gatsby';
import FbShare from '../../images/blog/share-icons/fb.svg';
import TwitterShare from '../../images/blog/share-icons/twitter.svg';
import LinkedinShare from '../../images/blog/share-icons/linkedin.svg';
import InstaShare from '../../images/blog/share-icons/insta.svg';
import EmailShare from '../../images/blog/share-icons/email.svg';
import { openURL } from '../../utils';
import './styles.scss';

const BlogFooter = () => (
  <footer className="blog-footer">
    <div className="container">
      <section className="links-container ">
        <div className="share">
          <div className="share-icons-container">
            <a
              href="https://www.facebook.com/klubworks/"
              className="share-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FbShare} alt="facebook" />
            </a>
            <a
              href="https://twitter.com/klubworks"
              className="share-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TwitterShare} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/klubworks/"
              className="share-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedinShare} alt="linkedin" />
            </a>
            <a
              href="https://www.instagram.com/klubworks/"
              className="share-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={InstaShare} alt="insta" />
            </a>
            <a
              href="mailto:contact@klubworks.com"
              className="share-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={EmailShare} alt="email" />
            </a>
          </div>
          <p>Investing in making life joyful</p>
        </div>
        <div>
          <p>Flexible Growth Capital for Loved Brands</p>
          <div className="btn-container">
            <button type="button" className="btn btnklub1" onClick={() => openURL('brand_app_signup')}>
              Get Funded
            </button>
            <button type="button" className="btn btnklub2" onClick={() => openURL('patron-app_signup')}>
              Invest Now
            </button>
          </div>
        </div>
      </section>
      <div className="divider-container ">
        <hr />
      </div>
      <nav className="">
        <p>
          ©
          {new Date().getFullYear()}
          , Ken Capital Technologies Pte Ltd.
        </p>
        <hr className="mobile-hr" />
        <div className="nav-links">
          <ul className="nav-list-item-container">
            <li className="nav-list-item">
              <Link to="/patrons">Patrons</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/brands">Brands</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/partners">partners</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </footer>
);

export default BlogFooter;
