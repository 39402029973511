/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import './styles.scss';
import Logo from '../../../static/logo/white-colored-logo.svg';

const hamburgerMenuStyle = {
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  right: 0,
  transition: '0.1s',
  display: 'flex',
  flexDirection: 'row'
};

const dblock = {
  display: 'block'
};

const dnone = {
  display: 'none'
};
const menuHeader = {
  display: 'flex',
  flexDirection: 'row',
  // backgroundColor: '#d8d8d8',
  backgroundColor: '#1518af',
  height: '64px',
  alignItems: 'center'
};

const BlogHeader = () => {
  const [isClicked, toggleClick] = useState(false);
  // const [selectedTab, setSelectedTab] = useState('');

  const data = useStaticQuery(graphql`
    {
      allGhostTag(filter: { visibility: { eq: "public" }, slug: {ne: "revenue-based-financing"} }) {
        edges {
          node {
            id
            slug
            feature_image
            name
          }
        }
      }
    }
  `);
  const { edges } = data.allGhostTag;

  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   if (url.pathname.includes('category')) {
  //     const pathName = url.pathname.split('/');
  //     const currentPage = pathName[pathName.length - 1];
  //     setSelectedTab(currentPage);
  //     console.log({ url, currentPage, pathName });
  //   }
  // }, []);

  const onClick = () => {
    toggleClick(!isClicked);
    let body = null;
    if (!isClicked) {
      body = document && document.body;
      body.style.overflow = 'hidden';
    } else {
      body = document && document.body;
      body.style.overflow = 'auto';
    }
  };

  return (
    <header className="blog-header">
      <nav className="headerWrapper container">
        <div className="logo-container">
          <Link to="/" className="klubIconLink">
            <img src={Logo} alt="main logo" />
          </Link>
          <Link to="/blog" className="blog-name">Kaleidoscope</Link>
        </div>
        <div className="linkWrapper">
          <div
            className="mobileHeader"
            style={isClicked ? hamburgerMenuStyle : { right: '-100vw' }}
          >
            <div
              style={{
                opacity: 0.4,
                width: '40vw',
                backgroundColor: '#000000'
              }}
              onClick={onClick}
            />
            <div
              className="hamburgerMenu"
              style={{ width: '60vw', backgroundColor: '#F2F2F2' }}
            >
              <div style={isClicked ? menuHeader : {}}>
                <div
                  className="menuText"
                  style={isClicked ? dblock : dnone}
                >
                  <Link to="/" className="klubIconLink">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
                <div
                  className="hamburgerWrapper"
                  onClick={() => onClick()}
                  style={isClicked ? { top: 'unset' } : {}}
                >
                  <div
                    className="hamburger"
                    style={
                      isClicked
                        ? {
                          transform: 'rotate(45deg)',
                          position: 'absolute',
                          top: 0,
                          backgroundColor: '#ffffff'
                        }
                        : {}
                    }
                  />
                  <div
                    className="hamburger"
                    style={{
                      backgroundColor: isClicked ? 'transparent' : 'white'
                    }}
                  />
                  <div
                    className="hamburger"
                    style={
                      isClicked
                        ? {
                          transform: 'rotate(-45deg)',
                          position: 'absolute',
                          top: 0,
                          backgroundColor: '#ffffff'
                        }
                        : {}
                    }
                  />
                </div>
              </div>
              <div className="hamburgerMenuWrapper">
                {edges
                  && edges.map(({ node }) => (
                    <Link
                      to={`/blog/category/${node.slug}`}
                      className="headerLink"
                      style={isClicked ? dblock : {}}
                      key={node.id}
                      onClick={() => onClick()}
                    >
                      {node.name}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          {edges
            && edges.map(({ node }) => (
              <Link
                to={`/blog/category/${node.slug}`}
                className="headerLink underline-from-left"
                key={node.id}
                activeClassName="active"
              >
                {node.name}
              </Link>
            ))}
        </div>
      </nav>
    </header>
  );
};

export default BlogHeader;
